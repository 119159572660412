<template>
  <div :style="format" id="soalPdf">
    <div id="headerPdf" class="mx-5">
      <div class="d-flex justify-content-between align-items-center mt-4">
        <div>
          <img
            :src="institusi ? institusi.logo : '/Logo-Hasan.png'"
            width="100px"
            alt
          />
        </div>
        <div class="text-center ml-4">
          <h3>{{ institusi ? institusi.nama.toUpperCase() : "-" }}</h3>
          <div>{{ institusi.alamat ? institusi.alamat.lokasi : "-" }}</div>
          <div>E-Mail : {{ institusi ? institusi.email : "-" }}</div>
        </div>
        <div v-if="!hideSecretDocument">
          <img
            src="@/assets/dokumen.png"
            width="150px"
            height="70px"
            alt
          />
        </div>
      </div>
      <hr style="border: 1px solid black" />
    </div>
    <div class="mx-5" id="codeOfConduct">
      <h3 class="text-center" v-if="['PTS', 'PH', 'PAS'].includes(detailCbt.tipe)">
        {{ detailCbt.tipePanjang }} ({{ detailCbt.tipe }})
      </h3>
      <h3 v-else class="text-center">
        {{ detailCbt.tipe }}
      </h3>
      <h3 class="text-center">
        SEMESTER {{ semester }} -
        TAHUN PELAJARAN {{ tahun }}
      </h3>
      <template v-if="detailMurid">
        <div class="d-flex">
          <div :class="detailMurid ? 'w-50' : 'w-100'">
            <table border="0" class="mt-3 font-size-14">
              <tr>
                <td>Nama Murid</td>
                <td class="px-4">:</td>
                <td>{{ detailMurid.nama }}</td>
              </tr>
              <tr>
                <td>NIS</td>
                <td class="px-4">:</td>
                <td>{{ detailMurid.nis || '-'}}</td>
              </tr>
              <tr>
                <td>Kelas</td>
                <td class="px-4">:</td>
                <td>{{ kelas }}</td>
              </tr>
              <tr>
                <td width="45%">Mata Pelajaran</td>
                <td class="px-4">:</td>
                <td>{{ namaMapel }}</td>
              </tr>
            </table>
          </div>
          <div class="w-50">
            <table border="0" class="mt-3 font-size-14">
              <tr>
                <td width="45%">Total Nilai</td>
                <td class="px-4">:</td>
                <td>{{ detailMurid.total_nilai || '-' }}</td>
              </tr>
              <tr>
                <td>Hari, Tanggal</td>
                <td class="px-4">:</td>
                <td>{{ tanggalUjian }}</td>
              </tr>
              <tr>
                <td>Jumlah Soal</td>
                <td class="px-4">:</td>
                <td>{{ detailMurid.jumlah_soal || '-' }} soal</td>
              </tr>
              <tr>
                <td>Nama Guru</td>
                <td class="px-4">:</td>
                <td>{{ detailCbt.createdBy || '-' }}</td>
              </tr>
            </table>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <table border="0" class="mt-3 font-size-14">
            <tr>
              <td>Hari, Tanggal</td>
              <td class="px-4">:</td>
              <td>{{ tanggalUjian || '-' }}</td>
            </tr>
            <tr>
              <td width="45%">Mata Pelajaran</td>
              <td class="px-4">:</td>
              <td>{{ namaMapel }}</td>
            </tr>
            <tr>
              <td>Kelas</td>
              <td class="px-4">:</td>
              <td>{{ kelas }}</td>
            </tr>
            <tr>
              <td>Durasi</td>
              <td class="px-4">:</td>
              <td>{{ detailCbt.durasi }} (menit)</td>
            </tr>
          </table>
        </div>
      </template>
      <template v-if="!hideCoC">
        <hr style="border: 1px solid black" />
        <span id="instruksiPdf" v-html="detailCbt.instruksi"></span>
      </template>
      <hr style="border: 1px solid black" />
    </div>
    <div
      v-for="(item, i) in dataSoal"
      :key="i"
      class="mx-5 content"
      style="margin-bottom: 20px"
    >
      <div class="d-flex flex-row">
        <div class="font-weight-bold">{{ numberQuestion(i) }}.</div>
        <div class="ml-2 eachSoalPdf">
          <span class="soal" ref="soal" v-html="item.soal"></span>
          <span v-if="checkEssayAnswer(item)">
            Jawaban:
          </span>
          <span v-if="checkEssayAnswer(item)" v-html="checkEssayAnswer(item)"></span>
          <template v-if="item.tipe_soal === 'Multiple Choice'">
            <div
              class="mt-2 d-flex"
              v-for="(itemJawaban, j) in item.jawaban"
              :key="j"
            >
              <div style="position: relative;">
                <div v-if="checkAnswerKey(item, String.fromCharCode(65 + j))" class="answer" />
                {{String.fromCharCode(65 + j)}}.&nbsp;
              </div>
              <span class="soal" ref="jawaban" v-html="itemJawaban"></span>
            </div>
            <div class="mt-3 font-weight-bold">
              <span
                v-if="item.tipe_soal === 'Multiple Choice'"
                class="mr-4"
                >Kunci Jawaban : {{ item.kunci_jawaban }}</span
              >
              <span class="mr-4"
                >Bobot Soal : {{ item.bobot_soal }}</span
              >
              <span class="mr-4"
                >Kriteria : {{ item.kriteria }}</span
              >
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { renderMathMl } from '@/helpers/mathjax'
export default {
  data() {
    return {}
  },
  props: ['detailCbt', 'format', 'kelas', 'tanggalUjian', 'dataSoal', 'options', 'listJawaban', 'detailMurid'],
  computed: {
    institusi() {
      return this.$store.state.master.institusi
    },
    semester() {
      return this.detailCbt.tahun_ajaran?.semester?.toUpperCase()
    },
    tahun() {
      return this.detailCbt.tahun_ajaran?.tahun
    },
    namaMapel() {
      return this.detailCbt.mata_pelajaran?.nama
    },
    hideSecretDocument() {
      return this.options?.hideSecretDocument
    },
    hideCoC() {
      return this.options?.hideCoC
    },
  },
  methods: {
    numberQuestion(index) {
      return ++index
    },
    checkAnswerKey(dataJawaban, abjad) {
      if (!this.listJawaban?.length) return false
      const { id } = dataJawaban
      const jawabanMuridObj = this.listJawaban.find(jwb => jwb.id_soal === id)
      if (!jawabanMuridObj?.jawaban) return false
      return jawabanMuridObj?.jawaban?.toLowerCase() === abjad?.toLowerCase()
    },
    checkEssayAnswer(dataJawaban) {
      if (!this.listJawaban?.length) return null
      const { id } = dataJawaban
      const jawabanMuridObj = this.listJawaban.find(jwb => jwb.id_soal === id)
      if (!jawabanMuridObj?.jawaban || jawabanMuridObj?.tipe_soal !== 'Essay') return null
      // console.log(jawabanMuridObj.jawaban, 'jawaban')
      return jawabanMuridObj.jawaban
    },
  },
  mounted() {
    let arrayOfRef = []
    const soal = this.$refs.soal
    const jawaban = this.$refs.jawaban
    if (soal || jawaban) {
      if (soal) {
        if (Array.isArray(soal)) arrayOfRef = arrayOfRef.concat(soal)
        else arrayOfRef.push(soal)
      }

      if (jawaban) {
        if (Array.isArray(jawaban)) arrayOfRef = arrayOfRef.concat(jawaban)
        else arrayOfRef.push(jawaban)
      }

      renderMathMl(arrayOfRef)
    }
  },
}
</script>
<style lang="scss">
.eachSoalPdf {
  .answer {
    position: absolute;
    top: 0;
    left: -3px;
    border: 1px solid black;
    width: 100%;
    height: 100%;
    border-radius: 50px;
  }
}
</style>
